import { getUserType } from 'src/app/@shared/helpers/role';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api/api.service';
import { CrudService } from 'src/app/@core/services/crud/crud.service';

@Injectable({
    providedIn: 'root'
})
export class ClientsService extends CrudService {
    readonly apiUrl: string = `/v1/${getUserType()}/clients`;

    constructor(apiService: ApiService) {
        super(apiService);
    }
}
