import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private messageService: MessageService) {}

    getMsgs() {
        return this.messageService.messageObserver;
    }

    add(message: { severity: string; summary: string; detail: string; life?: number }) {
        this.messageService.add({ ...message });
    }

    addMultiple(messages: { severity: string; summary: string; detail: string }[]) {
        this.messageService.addAll(messages);
    }

    clear() {
        this.messageService.clear();
    }
}
