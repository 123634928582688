import { getUserType } from 'src/app/@shared/helpers/role';
import { ApiService } from './../api/api.service';
import { BehaviorSubject, Observable, tap, catchError, EMPTY, throwError, map, switchMap, of } from 'rxjs';
import { SystemType } from './../../../@shared/enums/system-type.enum';
import { Injectable } from '@angular/core';
import { AdminLoginCredentials } from 'src/app/@shared/models/admin-login-credentials.model';
import { ClientLoginCredentials } from 'src/app/@shared/models/client-login-credentials.model';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    readonly apiUrl: string = `/v1/`;
    // readonly apiUrl: string = `/v1/${getUserType()}`;
    // readonly apiUrl: string = '/v1/client';
    // readonly apiUrl: string = '/v1/client';
    systemType!: SystemType;
    currentUser: any;

    private _isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _isAdminReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    isLoggedIn: boolean = false;
    get isLoggedIn$(): Observable<boolean> {
        return this._isLoggedIn$;
    }
    get isAdminReady$(): Observable<boolean> {
        return this._isAdminReady$;
    }

    constructor(private apiService: ApiService, private router: Router) {}

    checkIfIsLoggedIn(): boolean {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');
        const is_ready = localStorage.getItem('is_ready');
        if (token && user) {
            this.isLoggedIn = true;
            this._isLoggedIn$.next(true);
            this.currentUser = JSON.parse(user);
            // convention from string to bool
            this._isAdminReady$.next(is_ready == 'true');
        }
        return this.isLoggedIn;
    }

    checkAdminReadyStatus(): boolean {
        return this._isAdminReady$.getValue();
    }
    changeAdminReadyStatus(status: boolean) {
        this.apiService.post(`${this.apiUrl}${getUserType()}/set-ready-status`, { status }).subscribe();
        localStorage.setItem('is_ready', status.toString());
        this._isAdminReady$.next(status);
    }

    forgetPassword(email: string, sysType: string): Observable<any> {
        return this.apiService.post(`${this.apiUrl}${sysType}/password/forgot`, { email });
    }

    getUserLang() {
        return localStorage.getItem('lang');
    }

    logout() {
        this.apiService.post(`${this.apiUrl}${getUserType()}/logout`, {}).subscribe();
        const role = getUserType();
        const loginRoute = `/${role}/login`;
        localStorage.clear();
        // this.clearCookies();
        this.isLoggedIn = false;
        // this._isLoggedIn$.next(false);
        this.router.navigate([loginRoute]);
    }

    clearCookies() {
        document.cookie.split(';').forEach(function (c) {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
    }
}
