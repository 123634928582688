<button pButton pRipple type="button" icon="el-icon-map-location" class="p-button-danger p-button-custom col-sm-12" (click)="open()">Map</button>
<p-dialog header="Location" [(visible)]="isOpen" [style]="{ width: '75vw' }" [maximizable]="true" (onHide)="onClose()">
    <!-- center: { lat, lng }, -->
    <!-- draggable: true -->

    <div class="col-md-12 mb-1">
        <p-table [value]="[1]">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 100px">{{ 'Tables.Country' | translate }}</th>
                    <th style="min-width: 100px">{{ 'Tables.City' | translate }}</th>
                    <th style="min-width: 100px">{{ 'Tables.Zone' | translate }}</th>
                    <th style="min-width: 100px">{{ 'Tables.Governorate' | translate }}</th>
                    <th style="min-width: 100px">{{ 'Tables.District' | translate }}</th>
                    <th style="min-width: 100px">{{ 'Tables.StreetNo' | translate }}</th>
                    <th style="min-width: 100px">{{ 'Tables.StreetName' | translate }}</th>
                    <th style="min-width: 100px">{{ 'Tables.BuildingNo' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body">
                <tr>
                    <td style="min-width: 100px">
                        {{ location?.country?.name }}
                    </td>
                    <td style="min-width: 100px">{{ location?.city?.name }}</td>

                    <td style="min-width: 100px">{{ location?.zone?.name }}</td>
                    <td style="min-width: 100px">
                        {{ location?.governorate?.name }}
                    </td>
                    <td style="min-width: 100px">
                        {{ location?.district?.name }}
                    </td>
                    <td style="min-width: 100px">{{ location?.streetNo }}</td>
                    <td style="min-width: 100px">{{ location?.streetName }}</td>
                    <td style="min-width: 100px">{{ location?.buildingName }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-gmap
        #gmap
        [options]="{ center: { lat, lng }, zoom: 13 }"
        [overlays]="overlays"
        [style]="{ width: '100%', height: '800px' }"
        (onOverlayDragEnd)="drag($event)"
    ></p-gmap>
    <!-- <p-gmap
        [options]="{
            center: { lat, lng },
            zoom: 12
        }"
        [style]="{ width: '100%', height: '320px' }"
        [overlays]="overlays"
    ></p-gmap> -->
    <ng-template pTemplate="footer">
        <p-button label="OK" styleClass="p-button-danger p-button-custom" (click)="update(); isOpen = false"></p-button>
    </ng-template>
</p-dialog>
