import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();
import { Subject, Observable } from 'rxjs';

export function readFileAsUrl(file: File): Observable<string | ArrayBuffer> {
  const reader = new FileReader();
  let url: Subject<string | ArrayBuffer> = new Subject<string | ArrayBuffer>();
  reader.onload = (e) => {
    url.next(reader.result!);
    url.complete();
  };
  reader.readAsDataURL(file);
  return url;
}

export const phoneRegions = PhoneNumberUtil.getInstance()
  .getSupportedRegions()
  .map((region: string) => ({
    regionName: region,
    regionCode: PhoneNumberUtil.getInstance().getCountryCodeForRegion(region),
  })) as { regionName: string; regionCode: number }[];

/**
 * Returns national phone number from a compact phone number returned from the API,
 * If compactPhoneNumber is null or undefined, it returns an empty string
 *
 * @export
 * @param {string} compactPhoneNumber The phone number in the compact form (+{regionCode}{nationalPhoneNumber})
 * @param {string} [defaultRegionCode='EG'] The default region if the compact number is missing the region
 * @return {string}
 */
export function getPhoneNumber(
  compactPhoneNumber: string,
  defaultRegionCode: string = '20'
): string {
  if (compactPhoneNumber) {
    let phoneNumber: PhoneNumber;
    try {
      phoneNumber = phoneNumberUtil.parse(compactPhoneNumber);
    } catch {
      phoneNumber = phoneNumberUtil.parse(
        '+' + defaultRegionCode + compactPhoneNumber
      );
    }
    if (phoneNumber.getCountryCode()?.toString() == '20') {
      return '0' + phoneNumber.getNationalNumber()?.toString();
    } else {
      return phoneNumber.getNationalNumber()?.toString() || '';
    }
  } else {
    return '';
  }
}

export function getRegionCode(
  compactPhoneNumber: string,
  defaultRegionName: string = 'EG'
):
  | {
      regionName: string;
      regionCode: number;
    }
  | undefined {
  try {
    const phoneNumber: PhoneNumber = phoneNumberUtil.parse(compactPhoneNumber);
    return phoneRegions.find(
      (reg) => phoneNumber.getCountryCode() === reg.regionCode
    );
  } catch {
    return phoneRegions.find((reg) => reg.regionName === defaultRegionName);
  }
}

export class Constraint_NumberField {
  static field_KeyDown(
    keyboardEvent: KeyboardEvent,
    evaluationFunc?: () => boolean | undefined
  ): boolean | undefined {
    const isNumber =
      !!Number(keyboardEvent.key) || Number(keyboardEvent.key) === 0;
    let allowedKeys = [
      'ArrowRight',
      'ArrowLeft',
      'ArrowUp',
      'ArrowDown',
      'Backspace',
    ];

    if (allowedKeys.includes(keyboardEvent.code)) {
      return true;
    } else if (
      keyboardEvent.ctrlKey &&
      (keyboardEvent.code === 'KeyV' ||
        keyboardEvent.code === 'KeyC' ||
        keyboardEvent.code === 'KeyX' ||
        keyboardEvent.code === 'KeyA')
    ) {
      return true;
    } else if (!isNumber) {
      return false;
    }

    return evaluationFunc ? evaluationFunc() : undefined;
  }

  static field_KeyUp(
    keyboardEvent: KeyboardEvent,
    evaluationFunc?: () => boolean | undefined
  ): boolean | undefined {
    return evaluationFunc ? evaluationFunc() : undefined;
  }

  static field_Paste(
    clipboardEvent: ClipboardEvent,
    control: AbstractControl,
    formatFunc?: (text: string) => string
  ): boolean | undefined {
    // Get pasted text
    let pastedText = clipboardEvent.clipboardData?.getData('Text');

    // Reformat the text
    if (pastedText) {
      pastedText = pastedText.replace(/(?!\d)[^\d]/g, '');
    }
    if (formatFunc) {
      pastedText = formatFunc(pastedText ?? '');
    }

    // text for test ===> gfdgd1478dfds96gdfg3gfdgfd666643436gfhjg6666434jhgjhg3436hgfhf669898yupoyt

    control.setValue(pastedText);
    return false;
  }
}

export function objectToQuery(object: any) {
  var str = [];
  for (var p in object) {
    if (object.hasOwnProperty(p)) {
      str.push(p + '=' + object[p]);
    }
  }
  return str.join('&');
}

export function replace(value: string, replace: any, replacement: any) {
  return value.replaceAll(replace, replacement);
}
