import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { ToastService } from '../../services/toast.service';
import { LanguageService } from 'src/app/@core/services/language/language.service';

@Component({
    selector: 'etammn-delete-btn',
    templateUrl: './delete-btn.component.html',
    styleUrls: ['./delete-btn.component.scss']
})
export class DeleteBtnComponent {
    @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    constructor(
        private confirmationService: ConfirmationService,
        private location: Location,
        private toastService: ToastService,
        private languageService: LanguageService
    ) {}

    confirmDelete() {
        this.confirmationService.confirm({
            message: this.languageService.trans('Dialogs.' + 'delete'),
            header: this.languageService.trans('Buttons.' + 'Confirm'),
            acceptLabel: this.languageService.trans('Buttons.' + 'Yes'),
            rejectLabel: this.languageService.trans('Buttons.' + 'No'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                console.log('accept');
                this.delete.emit(true);
            },
            reject: () => {
                console.log('reject');
            }
        });
    }
}
