import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from 'src/app/@features/profile/profile.service';
import { FilesService } from 'src/app/@shared/services/files/files.service';
import { ToastService } from '../../services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'etammn-profile-card',
    templateUrl: './profile-card.component.html',
    styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent {
    constructor(
        private _sanitizer: DomSanitizer,
        private filesService: FilesService,
        private profileService: ProfileService,
        private toastService: ToastService
    ) {}
    @Input() profile?: any;
    imgBaseUrl = environment.Base;
    previewUrl: any;
    showSubmit = false;
    file!: File;
    changeProfilePicture(event: any) {
        this.showSubmit = true;
        this.file = event.target.files[0];
        let previewUrl = URL.createObjectURL(this.file);
        this.previewUrl = this._sanitizer.bypassSecurityTrustResourceUrl(previewUrl);
    }
    uploadProfilePicture() {
        const form = new FormData();
        form.append('file', this.file);
        this.filesService.uploadFile(form).subscribe({
            next: done => {
                let avatar = done.data;
                this.profileService.updateProfileData({ avatar }).subscribe(done => {
                    this.toastService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: done.message
                    });
                    this.profile.avatar = avatar;
                });
                this.showSubmit = false;
            }
        });
    }
}
