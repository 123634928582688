import { LangInterceptor } from './@core/interceptors/lang/lang.interceptor';
import { LanguageService } from './@core/services/language/language.service';
import { UserService } from './@core/services/user/user.service';
import { SharedModule } from './@shared/shared.module';
import { LoggedInGuard } from './@core/gaurds/logged-in/logged-in.guard';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppComponent } from './app.component';
import { LayoutComponent } from './@core/layout/layout.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './@core/interceptors/token/token.interceptor';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

const initializePrimeNgConfig = (primeConfig: PrimeNGConfig) => () => {
    primeConfig.ripple = true;
};

const initializeUserService = (userService: UserService) => () => {
    userService.checkIfIsLoggedIn();
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, LayoutComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        SlideMenuModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        LoggedInGuard,
        ConfirmationService,
        MessageService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializePrimeNgConfig,
            deps: [PrimeNGConfig],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeUserService,
            deps: [UserService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LangInterceptor,
            multi: true
        },
        // { provide: LOCALE_ID, useValue: 'ar-EG' },
        LanguageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
