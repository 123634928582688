<div class="card">
    <div class="top">
        <div
            class="photo"
            [ngClass]="{ event: firedEvent.sysId === system.id, alarm: firedEvent.eventType === 'ALARM', fault: firedEvent.eventType !== 'ALARM' }"
        >
            <img [src]="system.avatar" />
        </div>
        <p
            class="status p-button"
            [ngClass]="{
                'p-button-danger': system.alarmCount,
                'p-button-warning': system.faultCount,
                'p-button-success': !system.alarmCount && !system.faultCount
            }"
        >
            {{ system.currentStatus.localized_name }}
        </p>
    </div>
    <div class="middle">
        <div class="m-top">
            <h1 class="title">{{ system.location?.buildingName }}</h1>
            <a>{{ system.location?.country?.name }}</a>
            <a>{{ ' / ' + system.location?.district?.name }}</a>
        </div>
        <div>
            <span class="indicator" [ngClass]="{ online: system.isOnline, offline: !system.isOnline }"></span>
            <span class="status" [ngClass]="{ online: system.isOnline, offline: !system.isOnline }">{{
                system.isOnline ? ('Heads.ONLINE' | translate) : ('Heads.OFFLINE' | translate)
            }}</span>
        </div>
        <span class="mode">
            <ng-container *ngIf="system.currentMode.localized_name">
                {{ system.currentMode.localized_name }}
            </ng-container>
            <ng-container *ngIf="!system.currentMode.localized_name"> - </ng-container>
        </span>
    </div>
    <div class="bottom">
        <div class="statistics-card danger">
            <span>{{ 'Titles.Alarm' | translate }} {{ system.alarmCount }}</span>
        </div>
        <div class="statistics-card yellow">
            <span>{{ 'Titles.Fault' | translate }} {{ system.faultCount }}</span>
        </div>
    </div>
    <button
        pButton
        pRipple
        type="button"
        [label]="'Buttons.Details' | translate"
        icon="pi pi-info-circle"
        class="side-button p-button-rounded p-button-danger p-button-custom"
        [routerLink]="['/client-systems', 'details', system.id]"
    ></button>
    <button
        pButton
        pRipple
        type="button"
        [label]="'Buttons.Events' | translate"
        icon="pi pi-eye"
        class="side-button p-button-rounded p-button-danger p-button-custom"
        style="top: 4rem"
        [routerLink]="['/client-systems', 'system-events', system.id]"
    ></button>
    <div class="bottom-buttons-container">
        <button
            pButton
            pRipple
            [disabled]="!system.isOnline || counter || (!system.system_control && userType == SystemType.Client)"
            (click)="openReset()"
            type="button"
            [label]="'Buttons.Reset' | translate"
            icon="pi el-icon-refresh-left"
            class="bottom-button p-button-outlined p-button-primary"
        >
            <span class="counter" *ngIf="counter">{{ counter }}</span>
        </button>
        <button
            pButton
            pRipple
            [disabled]="!system.isOnline || counter || (!system.system_control && userType == SystemType.Client)"
            (click)="openSilent()"
            type="button"
            [label]="'Buttons.Silent' | translate"
            icon="pi el-icon-close-notification"
            class="bottom-button p-button-outlined p-button-danger"
        >
            <span class="counter" *ngIf="counter">{{ counter }}</span>
        </button>
    </div>
    <p-button
        class="changeModeBtn"
        [disabled]="!system.isOnline || counter > 0 || (!system.system_control && userType == SystemType.Client)"
        (click)="toggleChangeModeModel()"
        icon="pi pi-external-link"
        styleClass="p-button-danger p-button-custom p-button-outlined"
        [label]="'Buttons.changeMode' | translate"
    ></p-button>
</div>
<!--
<p-dialog header="Reset" [draggable]="false" [closable]="false" [visible]="resetVisible" [style]="{ width: '50vw' }">
  <p>System has been reset</p>
  <p style="font-size: 3rem; text-align: center">{{ counter }}</p>
</p-dialog>
<p-dialog header="Silent" [draggable]="false" [closable]="false" [visible]="silentVisible" [style]="{ width: '50vw' }">
  <p>System has been silent</p>
  <p style="font-size: 3rem; text-align: center">{{ counter }}</p>
</p-dialog> -->

<p-dialog
    [header]="system.location?.buildingName"
    [draggable]="false"
    [closable]="false"
    [visible]="chnageModeVisible || (showChnageModeVisibleModal.sysId == system.id && showChnageModeVisibleModal.show == true)"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '50vw', height: '400px' }"
    [draggable]="false"
    [resizable]="false"
>
    <div class="row">
        <div class="col-md-6 mb-1">
            <label for="float-input">{{ 'Tables.Mode' | translate }}</label>
            <p-dropdown [options]="modes" optionLabel="localized_name" placeholder="Select" [(ngModel)]="mode"></p-dropdown>
            <!-- <etammn-validation-error name="Role" [control]="getControl('role_id')"></etammn-validation-error> -->
        </div>
        <div class="col-md-6 mb-1">
            <label for="float-input">{{ 'Tables.Time' | translate }}</label>
            <p-dropdown
                [disabled]="!(mode && mode.withDuration)"
                [options]="times"
                optionLabel="localized_name"
                placeholder="Select"
                [(ngModel)]="time"
            ></p-dropdown>
            <!-- <etammn-validation-error name="Role" [control]="getControl('role_id')"></etammn-validation-error> -->
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button
            (click)="chnageModeVisible = false; showChnageModeVisibleModal.show = false"
            [label]="'Buttons.Cancel' | translate"
            styleClass="p-button-danger p-button-custom p-button-outlined"
        ></p-button>
        <p-button (click)="setMode(mode, time)" [label]="'Buttons.changeMode' | translate" styleClass="p-button-danger p-button-custom"></p-button>
    </ng-template>
</p-dialog>
