<!-- <div class="toggle-content">
    <button class="toggle-btn" (click)="toggle()">
        <fa-icon [icon]="faBars"></fa-icon>
    </button>
</div> -->
<div class="wrapper" [ngClass]="{ 'menu-closed': !isOpen }">
    <i (click)="toggleSideMenu()" class="pi pi-align-justify menu-btn"></i>

    <div class="top-menu d-flex-col">
        <ng-container *ngFor="let item of menuTop">
            <a class="item" [routerLink]="item.link" routerLinkActive="app-active-link" *ngIf="userType === item.role && item.permissions">
                <i class="pi pi-{{ item.icon }} text-{{ item.iconColor }}"></i>
                <span>{{ item.name | translate }}</span>
            </a>
        </ng-container>
    </div>
    <div class="bottom-menu d-flex-col">
        <h3 class="title">{{ 'Titles.Menus' | translate }}</h3>
        <ng-container *ngFor="let item of menuBottom">
            <ng-container *ngIf="!item.children.length">
                <a
                    class="item"
                    [routerLink]="item.link"
                    routerLinkActive="app-active-link"
                    [routerLinkActiveOptions]="{ exact: true }"
                    *ngIf="(userType === item.role && item.permissions) || (item.multiRoles?.includes(userType) && item.permissions)"
                >
                    <fa-icon [icon]="item.icon"></fa-icon>
                    <span>{{ item.name | translate }}</span>
                </a>
            </ng-container>
            <ng-container *ngIf="item.children.length">
                <p-accordion *ngIf="userType === item.role && item.permissions">
                    <p-accordionTab [selected]="selected(item.flag)">
                        <ng-template pTemplate="header">
                            <fa-icon [icon]="item.icon"></fa-icon>
                            <span [routerLink]="item.link" routerLinkActive="app-active-parent">{{ item.name | translate }}</span>
                        </ng-template>
                        <ng-container *ngFor="let child of item.children">
                            <a
                                class="item"
                                [routerLink]="item.link + '/' + child.link"
                                routerLinkActive="app-active-link"
                                *ngIf="userType === child.role || child.permissions"
                            >
                                <i class="pi pi-{{ child.icon }}"></i>
                                <span>{{ child.name | translate }}</span>
                            </a>
                        </ng-container>
                    </p-accordionTab>
                </p-accordion>
            </ng-container>
        </ng-container>
    </div>
</div>
