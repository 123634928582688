import { UserService } from 'src/app/@core/services/user/user.service';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'src/app/@features/profile/profile.service';
import { ToastService } from 'src/app/@shared/services/toast.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    defaultLang = 'en';
    languages = [
        {
            name: 'English',
            key: 'en'
        },
        {
            name: 'Arabic',
            key: 'ar'
        }
    ];

    constructor(
        private translateService: TranslateService,
        @Inject(DOCUMENT) private document: Document,
        private userService: UserService,
        private profileService: ProfileService,
        private toastService: ToastService
    ) {
        const userLang = this.userService.getUserLang();
        this.setLang(userLang ? userLang : this.defaultLang);
    }

    setLang(lang: string | null) {
        const language: string = lang || 'en';
        const direction = language === 'ar' ? 'rtl' : 'ltr';
        const oppositeDirection = language === 'ar' ? 'ltr' : 'rtl';
        this.translateService.use(language);
        this.translateService.defaultLang = language;
        this.document.documentElement.lang = language;
        this.document.body.dir = direction;
        this.document.body.classList.remove(oppositeDirection);
        this.document.body.classList.add(direction);
        this.saveLang(language);
    }

    saveLang(lang: string) {
        localStorage.setItem('lang', lang);
    }

    changeLang(lang: string) {
        this.updateProfileLang(lang);
        this.setLang(lang);
        window.location.reload();
    }

    updateProfileLang(lang: string) {
        this.profileService.updateProfileData({ lang }).subscribe({
            next: rsp => {
                this.toastService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: rsp.message
                });
            }
        });
    }

    get currentLang() {
        return this.translateService.currentLang;
    }

    trans(key: string) {
        return this.translateService.instant(key);
    }
}
