<div class="card">
    <div class="top">
        <div class="photo"><p-image [src]="showSubmit ? previewUrl : imgBaseUrl + profile?.avatar" [preview]="true" /></div>
        <p class="title" style="font-weight: bold">{{ profile?.username }}</p>

        <label for="upload">
            <span style="color: var(--main-color); cursor: pointer" aria-hidden="true">{{ 'Tables.ChangeProfilePicture' | translate }}</span>
            <input type="file" id="upload" accept="image/*" hidden (change)="changeProfilePicture($event)" />
        </label>

        <div class="col-md-8 col-sm-12" *ngIf="showSubmit" (click)="uploadProfilePicture()">
            <button pButton pRipple type="button" [label]="'Buttons.Submit' | translate" class="p-button-danger p-button-custom"></button>
        </div>
        <p-divider></p-divider>
    </div>
    <div class="mb-2">
        <div class="m-top">
            <div class="row">
                <div class="col-12">
                    <p style="text-align: center">{{ 'Tables.MemberDate' | translate }} {{ profile?.createdAt | date : 'MM/dd/yyyy' }}</p>
                </div>
                <div class="col-12">
                    <p style="text-align: center">{{ 'Tables.LastLogin' | translate }} {{ profile?.last_login | date : 'MM/dd/yyyy' }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
