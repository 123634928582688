import { getUserType } from 'src/app/@shared/helpers/role';
import { LanguageService } from './../../../@core/services/language/language.service';
import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import {
    faBars,
    faBriefcase,
    faBuilding,
    faBurger,
    faClipboardList,
    faCogs,
    faEarth,
    faFile,
    faFireExtinguisher,
    faGlobe,
    faTachometerAlt,
    faUserLock,
    faUsers,
    faVenusMars
} from '@fortawesome/free-solid-svg-icons';
import { SystemType } from '../../enums/system-type.enum';

@Component({
    selector: 'etammn-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {
    SystemType = SystemType;
    userType = getUserType();
    menuTop: any[] = [];
    menuBottom: any[] = [];
    // Icons
    faTachometerAlt = faTachometerAlt;
    faUserLock = faUserLock;
    faGlobe = faGlobe;
    faBuilding = faBuilding;
    faFireExtinguisher = faFireExtinguisher;
    faUsers = faUsers;
    faClipboardList = faClipboardList;
    faFile = faFile;
    faBriefcase = faBriefcase;
    faCogs = faCogs;
    faBars = faBars;
    @Input() isOpen: boolean = true;

    constructor(private router: Router, private languageService: LanguageService) {
        this.setupMenus();
    }
    toggleSideMenu() {
        this.isOpen = !this.isOpen;
    }
    setupMenus() {
        this.menuTop = [
            {
                name: 'Titles.General',
                icon: 'circle',
                link: 'monitoring/general',
                iconColor: 'info',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('monitoring', 'control')
            },
            {
                name: 'Titles.Alarm',
                icon: 'circle',
                link: 'monitoring/alarm',
                iconColor: 'danger',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('monitoring', 'control')
            },
            {
                name: 'Titles.Fault',
                icon: 'circle',
                link: 'monitoring/fault',
                iconColor: 'warning',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('monitoring', 'control')
            }
        ];
        this.menuBottom = [
            {
                name: 'Titles.Dashboard',
                icon: faTachometerAlt,
                link: '',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('monitoring', 'read')
            },
            {
                name: 'Titles.ManageUsers&Roles',
                flag: 'users-management',
                icon: faUserLock,
                link: 'users-management',
                role: SystemType.Admin,
                permissions: this.getPermission('admins', 'control'),
                children: [
                    {
                        name: 'SubTitles.Teams',
                        icon: 'circle',
                        link: 'teams',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('teams', 'control')
                    },
                    {
                        name: 'SubTitles.Roles',
                        icon: 'circle',
                        link: 'roles',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('roles', 'control')
                    },
                    {
                        name: 'SubTitles.Roles Templates',
                        icon: 'circle',
                        link: 'roles-templates',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('rolesTemplates', 'control')
                    },
                    {
                        name: 'SubTitles.UsersEG',
                        icon: 'circle',
                        link: 'users',
                        // role: SystemType.Admin
                        permissions: this.getPermission('admins', 'control')
                    },
                    {
                        name: 'SubTitles.UsersEGOnline',
                        icon: 'circle',
                        link: 'users/online',
                        // role: SystemType.Admin
                        permissions: this.getPermission('admins', 'control')
                    }
                ]
            },
            {
                name: 'Titles.ManageRegions',
                flag: 'regions',
                icon: faGlobe,
                link: 'regions',
                role: SystemType.Admin,
                permissions: this.getPermission('countries', 'control'),
                children: [
                    {
                        name: 'SubTitles.Countries',
                        icon: 'circle',
                        link: 'countries',
                        // role: SystemType.Admin
                        permissions: this.getPermission('countries', 'read')
                    },
                    {
                        name: 'SubTitles.Governorates',
                        icon: 'circle',
                        link: 'governorates',
                        // role: SystemType.Admin
                        permissions: this.getPermission('governorates', 'read')
                    },
                    {
                        name: 'SubTitles.Cities',
                        icon: 'circle',
                        link: 'cities',
                        // role: SystemType.Admin
                        permissions: this.getPermission('cities', 'read')
                    },
                    {
                        name: 'SubTitles.Zones',
                        icon: 'circle',
                        link: 'zones',
                        // role: SystemType.Admin
                        permissions: this.getPermission('zones', 'read')
                    },
                    {
                        name: 'SubTitles.Districts',
                        icon: 'circle',
                        link: 'districts',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('districts', 'read')
                    },
                    {
                        name: 'SubTitles.PropertyTypes',
                        icon: 'circle',
                        link: 'property-types',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('propertyTypes', 'read')
                    }
                ]
            },
            {
                name: 'Titles.ManagePartners',
                flag: 'partners-management',
                icon: faBuilding,
                link: 'partners-management/',
                role: SystemType.Admin,
                permissions: this.getPermission('partners', 'control'),
                children: [
                    // {
                    //     name: 'SubTitles.Partners',
                    //     icon: 'circle',
                    //     link: 'partners',
                    //     // role: SystemType.Admin,
                    //     permissions: this.getPermission('partners', 'read')
                    // },
                    // {
                    //     name: 'SubTitles.PartnersUsers',
                    //     icon: 'circle',
                    //     link: 'partners-users',
                    //     role: SystemType.Admin
                    // },
                    {
                        name: 'SubTitles.Maintenance',
                        icon: 'circle',
                        link: 'maintenance',
                        // role: SystemType.Admin
                        permissions: this.getPermission('maintenanceUsers', 'control')
                    },
                    {
                        name: 'SubTitles.CivilDefence',
                        icon: 'circle',
                        link: 'civil-defence',
                        // role: SystemType.Admin
                        permissions: this.getPermission('civilDefenceUsers', 'control')
                    }
                ]
            },
            {
                name: 'Titles.ManageDevices',
                flag: 'devices-management',
                icon: faFireExtinguisher,
                link: 'devices-management',
                role: SystemType.Admin,
                permissions: this.getPermission('manufacturers', 'control'),
                children: [
                    {
                        name: 'SubTitles.Manufacturers',
                        icon: 'circle',
                        link: 'manufacturers',
                        // role: SystemType.Admin
                        permissions: this.getPermission('manufacturers', 'read')
                    },
                    {
                        name: 'SubTitles.Brands',
                        icon: 'circle',
                        link: 'brands',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('brands', 'read')
                    },
                    {
                        name: 'SubTitles.Models',
                        icon: 'circle',
                        link: 'models',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('brands', 'read')
                    },
                    {
                        name: 'SubTitles.Protocols',
                        icon: 'circle',
                        link: 'protocols',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('protocols', 'read')
                    },
                    {
                        name: 'SubTitles.FirmwareVersions',
                        icon: 'circle',
                        link: 'firmware-versions',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('firmwareVersions', 'read')
                    },
                    {
                        name: 'SubTitles.SoftVersions',
                        icon: 'circle',
                        link: 'software-versions',
                        // role: SystemType.Admin,
                        permissions: this.getPermission('softwareVersions', 'read')
                    },
                    {
                        name: 'SubTitles.BuadRates',
                        icon: 'circle',
                        link: 'baud-rates',
                        // role: SystemType.Admin
                        permissions: this.getPermission('baudRates', 'read')
                    }
                ]
            },
            {
                name: 'Titles.ManageClients&Systems',
                icon: faUsers,
                link: 'clients',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('clients', 'control')
            },
            {
                name: 'Titles.ManageEventTypes',
                icon: faEarth,
                link: 'event-types',
                children: [],
                role: SystemType.Admin,
                Permissions: this.getPermission('admins', 'control')
            },
            {
                name: 'Titles.Logs',
                flag: 'logs',
                icon: faClipboardList,
                link: 'logs',
                role: SystemType.Admin,
                permissions: this.getPermission('logs', 'control'),
                children: [
                    {
                        name: 'SubTitles.Activities',
                        icon: 'circle',
                        link: 'activites',
                        // role: SystemType.Admin,
                        permissions: true
                    },
                    {
                        name: 'SubTitles.Exceptions',
                        icon: 'circle',
                        link: 'exceptions',
                        // role: SystemType.Admin,
                        permissions: true
                    }
                ]
            },
            {
                name: 'Titles.Reports',
                icon: faFile,
                // link: 'reports',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('reports', 'control')
            },
            {
                name: 'Titles.MaintenanceTest',
                icon: faBriefcase,
                // link: 'maintenance',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('settings', 'control')
            },
            {
                name: 'Titles.SystemSetting',
                icon: faCogs,
                // link: 'settings',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('settings', 'control')
            },
            {
                name: 'Titles.SystemVariables',
                icon: faEarth,
                link: 'site-variables',
                children: [],
                role: SystemType.Admin,
                permissions: this.getPermission('settings', 'control')
            },

            {
                name: 'Titles.Users',
                icon: faUserLock,
                link: 'users-management/users',
                children: [],
                role: SystemType.Client,
                multiRoles: [SystemType.Client, SystemType.Maintenance],
                permissions: this.getPermission('clientUsers', 'control') || this.getPermission('partnerUsers', 'control')
            }
            // {
            //     name: 'Titles.Users',
            //     icon: faUserLock,
            //     link: 'users-management/users',
            //     children: [],
            //     permissions: this.getPermission('clientUsers', 'read')
            // }
        ];
    }

    getPermission(group: string, access: string) {
        const permission = JSON.parse(localStorage.getItem('permissions') || '');
        return permission?.[group]?.[access];
    }

    selected(route: string) {
        return this.router.url.includes(route);
    }

    toggle() {
        this.isOpen = !this.isOpen;
    }
}
