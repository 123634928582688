import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportService } from '../../services/export.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
    selector: 'etammn-export-btn',
    templateUrl: './export-btn.component.html',
    styleUrls: ['./export-btn.component.scss']
})
export class ExportBtnComponent {
    @Input() fileName: string = '';
    @Input() data: any[] = [];
    @Output() exporting: EventEmitter<boolean> = new EventEmitter<boolean>();

    items = [
        {
            label: 'Print',
            icon: 'pi pi-print',
            command: () => {
                this.print();
            }
        },
        {
            label: 'Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                this.exportExcel();
            }
        },
        {
            label: 'Pdf',
            icon: 'pi pi-file-pdf',
            command: () => {
                this.exportPdf();
            }
        }
    ];

    constructor(private exportService: ExportService) {}

    print() {
        this.isLoading(true);
        window.print();
        setTimeout(() => {
            this.isLoading(false);
        }, 1000);
    }

    exportExcel() {
        this.isLoading(true);
        this.exportService.exportCSV(this.data, this.fileName, this.getHeadersList(this.data));
        setTimeout(() => {
            this.isLoading(false);
        }, 1000);
    }

    exportPdf() {
        this.isLoading(true);
        let DATA: any = document.getElementsByTagName('table')[0];
        html2canvas(DATA).then(canvas => {
            let fileWidth = 208;
            let fileHeight = (canvas.height * fileWidth) / canvas.width;
            const FILEURI = canvas.toDataURL('image/png');
            let PDF = new jsPDF('p', 'mm', 'a4');
            let position = 0;
            PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
            PDF.save(`${this.fileName}.pdf`);
            this.isLoading(false);
        });
    }

    getHeadersList(data: any[]) {
        const headers: string[] = [];
        if (data.length > 0) {
            const row = data[0];
            for (const key in row) {
                headers.push(key);
            }
        }
        return this.filterAllowedExportedFileds(headers);
    }

    isLoading(state: boolean) {
        this.exporting.emit(state);
    }

    /** allowed fields to export , -- useful to ignore unwanted date to export */
    filterAllowedExportedFileds(data: string[]) {
        let allowedFields = ['name', 'username', 'email', 'mobile', 'users_control', 'client_uuid', 'createdAt'];
        return data.filter(field => allowedFields.includes(field));
    }
}
