import { LanguageService } from './../services/language/language.service';
import { Observable } from 'rxjs';
import { SystemType } from './../../@shared/enums/system-type.enum';
import { UserService } from './../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { getUserType } from 'src/app/@shared/helpers/role';

@Component({
    selector: 'etammn-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    SystemType = SystemType;
    userType: string = getUserType();
    isLoggedIn$: Observable<boolean>;
    systemType!: SystemType;
    isCollapsed = true;
    items!: MenuItem[];
    currentUser: any;
    isOpen = true;

    constructor(private userService: UserService, public languageService: LanguageService) {
        this.userType;
        this.isLoggedIn$ = userService.isLoggedIn$;
        this.systemType = SystemType[getUserType().charAt(0).toUpperCase() + getUserType().slice(1)];
        this.currentUser = this.userService.currentUser;
    }

    logout() {
        this.userService.logout();
    }

    ngOnInit(): void {
        this.items = [
            {
                label: 'Users',
                routerLink: 'users'
            }
        ];
    }
    // toggleSideMenu() {
    //     this.isOpen = !this.isOpen;
    // }
}
