export function getUserType(data?: any): string {
    return localStorage.getItem('userType') || '';
    // let user;
    // if (data) {
    //   user = data;
    // } else {
    //   const getUser = localStorage.getItem('user');
    //   if (getUser) {
    //     user = JSON.parse(getUser);
    //   }
    // }
    // if (user) {
    //   const role = user['clientId'] ? 'client' : 'admin';

    //   return role;
    // }
    // return '';
    // const role = localStorage.getItem('role');

    // if (role) {
    //   return role;
    // }
    // const user = localStorage.getItem('user');
    // if (user) {
    //   const role = user['clientId'] ? 'client' : 'admin';
    //   return role;
    // }
    // return;
}
