import { Component, EventEmitter, Output } from '@angular/core';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { ToastService } from '../../services/toast.service';
import { LanguageService } from 'src/app/@core/services/language/language.service';

@Component({
    selector: 'etammn-reset-btn',
    templateUrl: './reset-btn.component.html',
    styleUrls: ['./reset-btn.component.scss']
})
export class ResetBtnComponent {
    @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private confirmationService: ConfirmationService, private toastService: ToastService, private languageService: LanguageService) {}

    dialog() {
        this.confirmationService.confirm({
            message: this.languageService.trans('Dialogs.' + 'proceed'),
            header: this.languageService.trans('Buttons.' + 'Confirm'),
            acceptLabel: this.languageService.trans('Buttons.' + 'Yes'),
            rejectLabel: this.languageService.trans('Buttons.' + 'No'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.toastService.add({
                    severity: 'info',
                    summary: 'Confirmed',
                    detail: 'You have accepted'
                });
                this.confirm.emit(true);
            },
            reject: (type: ConfirmEventType) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.toastService.add({
                            severity: 'error',
                            summary: 'Rejected',
                            detail: 'You have rejected'
                        });
                        break;
                    case ConfirmEventType.CANCEL:
                        // this.toastService.add({
                        //   severity: 'warn',
                        //   summary: 'Cancelled',
                        //   detail: 'You have cancelled',
                        // });
                        break;
                }
            }
        });
    }

    click() {
        this.dialog();
    }
}
