import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { ToastService } from '../../services/toast.service';
import { LanguageService } from 'src/app/@core/services/language/language.service';

@Component({
    selector: 'etammn-page-head',
    templateUrl: './page-head.component.html',
    styleUrls: ['./page-head.component.scss']
})
export class PageHeadComponent {
    @Input() title: string = '';
    @Input() icon: string = '';
    @Input() form: FormGroup = new FormGroup({});
    @Input() back: boolean = true;

    constructor(
        private location: Location,
        private toastService: ToastService,
        private confirmationService: ConfirmationService,
        private languageService: LanguageService
    ) {}

    backClicked() {
        this.location.back();

        // if (this.form.valid) {
        //     this.location.back();
        // } else {
        //     this.confirmationService.confirm({
        //         message: this.languageService.trans('Dialogs.' + 'proceed'),
        //         header: this.languageService.trans('Buttons.' + 'Confirm'),
        //         acceptLabel: this.languageService.trans('Buttons.' + 'Yes'),
        //         rejectLabel: this.languageService.trans('Buttons.' + 'No'),
        //         icon: 'pi pi-exclamation-triangle',
        //         accept: () => {
        //             this.location.back();
        //         },
        //         reject: (type: ConfirmEventType) => {
        //         }
        //     });
        // }
    }
}
