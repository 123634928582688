import { Component, Input } from '@angular/core';
import { faCalendar, faCalendarAlt, faCalendarCheck, faCalendarPlus, faClock, faStopwatch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'etammn-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss']
})
export class DateComponent {
    @Input() date!: Date;
    @Input() time: boolean = false;

    faCalendarAlt = faCalendarAlt;
    faClock = faClock;
}
