import { PermissionDirective } from './directives/permission.directive';
import { TranslateModule } from '@ngx-translate/core';
import { StatusSwitchComponent } from './components/status-switch/status-switch.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { TagComponent } from './components/tag/tag.component';
import { TagModule } from 'primeng/tag';
import { RouterModule } from '@angular/router';
import { PrimeNgModule } from './prime-ng.module';
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PhoneFieldComponent } from './components/phone-field/phone-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemCardComponent } from './components/system-card/system-card.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { PageHeadComponent } from './components/page-head/page-head.component';
import { ResetBtnComponent } from './components/reset-btn/reset-btn.component';
import { BackBtnComponent } from './components/back-btn/back-btn.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClientIdContactsComponent } from './components/client-id-contacts/client-id-contacts.component';
import { DateComponent } from './components/date/date.component';
import { ExportBtnComponent } from './components/export-btn/export-btn.component';
import { DeleteBtnComponent } from './components/delete-btn/delete-btn.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MapComponent } from '../@features/client/components/map/map.component';

const declarations = [
    PhoneFieldComponent,
    SystemCardComponent,
    SideBarComponent,
    ProfileCardComponent,
    PageHeadComponent,
    TagComponent,
    ValidationErrorComponent,
    ResetBtnComponent,
    BackBtnComponent,
    ClientIdContactsComponent,
    DateComponent,
    StatusSwitchComponent,
    PermissionDirective,
    ExportBtnComponent,
    DeleteBtnComponent,
    MapComponent
];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    NgxIntlTelInputModule,
    PrimeNgModule,
    TagModule,
    FontAwesomeModule,
    TranslateModule,
    NgxDatatableModule
];
@NgModule({
    declarations: [...declarations],
    imports: [RouterModule, ...imports],
    exports: [...declarations, ...imports]
})
export class SharedModule {}
