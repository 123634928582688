import { Component } from '@angular/core';

@Component({
  selector: 'etammn-back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss']
})
export class BackBtnComponent {

}
