import { UserService } from '../../services/user/user.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { catchError, Observable, throwError, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { ToastService } from 'src/app/@shared/services/toast.service';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
    constructor(private userService: UserService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const lang = localStorage.getItem('lang') || '';
        req = req.clone({
            headers: req.headers.set('lang', lang)
        });
        return next.handle(req);
    }
}
