import { ValidatorFn, AbstractControl } from '@angular/forms';
import {
  PhoneNumberUtil,
  PhoneNumber,
  PhoneNumberType,
} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function phoneValidator(
  countryCode: string,
  type: PhoneNumberType
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let validNumber = false;
    try {
      const phoneNumber: PhoneNumber = phoneNumberUtil.parse(
        control.value?.e164Number,
        countryCode
      );
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      let phoneType = phoneNumberUtil.getNumberType(phoneNumber);
      if (
        (type === PhoneNumberType.FIXED_LINE ||
          type === PhoneNumberType.MOBILE) &&
        phoneType === PhoneNumberType.FIXED_LINE_OR_MOBILE
      ) {
        validNumber = true;
      } else if (phoneType !== type) {
        validNumber = false;
      }
    } catch (e) {}

    return validNumber || control.value?.e164Number === undefined
      ? null
      : { wrongNumber: { value: control.value?.e164Number } };
  };
}
