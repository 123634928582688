import { LanguageService } from './@core/services/language/language.service';
import { Component } from '@angular/core';

@Component({
    selector: 'etammn-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private languageService: LanguageService) {}
}
