import { getUserType } from 'src/app/@shared/helpers/role';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api/api.service';
import { CrudService } from 'src/app/@core/services/crud/crud.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends CrudService {
    readonly apiUrl: string = `/v1/${getUserType()}/profile`;

    constructor(apiService: ApiService) {
        super(apiService);
    }

    updateProfileData(data: any): Observable<any> {
        return this.apiService.put(this.apiUrl, data);
    }
}
