import { ApiService } from 'src/app/@core/services/api/api.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FilesService {
    readonly apiUrl: string = '/v1/';

    constructor(private apiService: ApiService) {}

    uploadFile(data: FormData) {
        return this.apiService.post(`${this.apiUrl}uploadfile`, data);
    }
}
