import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NO_ERRORS_SCHEMA, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, Validators, FormControl } from '@angular/forms';
import { PhoneNumberType } from 'google-libphonenumber';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { startWith } from 'rxjs';
import { getRegionCode, getPhoneNumber, Constraint_NumberField } from '../../helpers/helper';
import { phoneValidator } from '../../validators/phone.validator';

@Component({
    selector: 'app-phone-field',
    templateUrl: './phone-field.component.html',
    styleUrls: ['./phone-field.component.scss']
})
export class PhoneFieldComponent implements AfterViewInit, OnChanges {
    @Input() placeholder: string = 'Phone Number';
    @Input() control: FormControl = new FormControl();
    @Input() type: PhoneNumberType.FIXED_LINE | PhoneNumberType.MOBILE = PhoneNumberType.MOBILE;
    @Input() required: boolean = true;
    @Input() isOpeningUp: boolean = false;
    @Input() currentLang: string = 'ar';
    @Input() showIcon: boolean = false; // Note: Styles is not configured when you set it to (true) value
    @Input() canShowError: boolean = true;
    @Input() phoneValidation: boolean = true;

    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    selectedCountry: CountryISO = 'eg' as CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.Qatar];
    maxLength: number = 15;

    constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['required']) {
            if (this.required) {
                this.control?.setValidators([Validators.required, Validators.minLength(4), phoneValidator(this.control?.value?.countryCode, this.type)]);
            } else {
                this.control?.setValidators([Validators.minLength(4), phoneValidator(this.control?.value?.countryCode, this.type)]);
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.required) {
            this.control?.setValidators([Validators.required, Validators.minLength(4), phoneValidator(this.control?.value?.countryCode, this.type)]);
        } else {
            this.control?.setValidators([Validators.minLength(4), phoneValidator(this.control?.value?.countryCode, this.type)]);
        }

        this.selectedCountry = getRegionCode(this.control?.value)!.regionName.toString().toLowerCase() as CountryISO;
        this.setMaxLength(this.selectedCountry);
        this.control?.setValue(getPhoneNumber(this.control?.value));
        this.cdr.detectChanges();

        this.control?.valueChanges.pipe(startWith('')).subscribe(val => {
            // logger.log(this.control?);
            if (this.currentLang === 'ar') {
                if (val) {
                    (this.elementRef.nativeElement as HTMLElement).classList.remove('empty-ar');
                } else {
                    (this.elementRef.nativeElement as HTMLElement).classList.add('empty-ar');
                }
            }
        });

        this.setFieldEvents();
    }

    onSelectedCountryChanged(event: any) {
        this.setMaxLength(event.iso2);
    }

    setMaxLength(countryIso: string) {
        if (countryIso === 'eg') {
            if (this.type === PhoneNumberType.FIXED_LINE) {
                this.maxLength = 10;
            } else if (this.type === PhoneNumberType.MOBILE) {
                this.maxLength = 11;
            }
        } else {
            this.maxLength = 15;
        }
    }

    getHtmlInputElement(): HTMLInputElement {
        let componentElement = this.elementRef.nativeElement as HTMLElement;
        // logger.log(componentElement.querySelector('input'));
        return componentElement.querySelector('input')!;
    }

    setFieldEvents(): void {
        let htmlInputElement: HTMLInputElement = this.getHtmlInputElement();
        htmlInputElement.addEventListener('keydown', e => {
            return Constraint_NumberField.field_KeyDown(e);
        });
        htmlInputElement.addEventListener('keyup', e => {
            return Constraint_NumberField.field_KeyUp(e);
        });
    }
}
