import { Component, EventEmitter, Input, Output } from '@angular/core';

import { interval, takeWhile } from 'rxjs';
import { SystemsService } from 'src/app/@features/systems/systems.service';
import { SystemType } from '../../enums/system-type.enum';
import { getUserType } from '../../helpers/role';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'etammn-system-card',
    templateUrl: './system-card.component.html',
    styleUrls: ['./system-card.component.scss']
})
export class SystemCardComponent {
    userType = getUserType();
    SystemType = SystemType;

    @Input() system: any;
    @Input() statusColor?: any;
    @Input() modes: any[] = [];
    @Input() times: any[] = [];
    @Input() firedEvent: any;
    @Input() showChnageModeVisibleModal = { sysId: 0, show: false };
    @Output() update: EventEmitter<boolean> = new EventEmitter<boolean>();
    chnageModeVisible: boolean = false;
    counter: number = 0;

    mode!: { localized_name: string; name: string; withDuration: boolean };
    time!: { localized_name: string; time: number };

    constructor(private service: SystemsService, private toastService: ToastService) {}

    toggleChangeModeModel() {
        if (!(!this.system.isOnline || this.counter > 0 || (!this.system.system_control && this.userType == SystemType.Client))) {
            this.chnageModeVisible = !this.chnageModeVisible;
        }
    }

    setMode(mode: any, time: any) {
        if (!mode) {
            return;
        }
        if (mode && mode.withDuration && !time) {
            return;
        }
        const modeData = {};
        if (mode) {
            modeData['currentMode'] = mode.name;
        }
        if (time) {
            modeData['time'] = time.time;
        }
        this.toggleChangeModeModel();
        this.service.setMode(this.system.id, modeData).subscribe({
            next: done => {
                this.toastService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: done.message
                });
            },
            error: () => {},
            complete: () => {
                this.update.emit(true);
            }
        });
    }

    openReset() {
        if (this.system.isOnline) {
            this.runCounter();
            this.service.reset(this.system.id).subscribe();
        }
    }

    openSilent() {
        if (this.system.isOnline) {
            this.runCounter();
            this.service.silent(this.system.id).subscribe();
        }
    }

    runCounter() {
        this.counter = 20;
        interval(1000)
            .pipe(takeWhile(() => this.counter > 0, true))
            .subscribe(count => {
                if (count === 20) {
                    return;
                }
                this.counter!--;
            });
    }
}
