import { AuthService } from 'src/app/@core/services/auth/auth.service';
import { Directive, ElementRef, ViewContainerRef, TemplateRef, Input, OnInit } from '@angular/core';
import { Permissions } from '../interfaces/permissions/permissions.interface';

@Directive({
    selector: '[etammnPermission]'
})
export class PermissionDirective implements OnInit {
    permission!: Permissions | null;

    @Input() group!: string;
    @Input() access!: string;

    constructor(private el: ElementRef, private authService: AuthService) {
        this.permission = this.authService.getPermissions();
        console.table(this.permission);
    }

    ngOnInit(): void {
        this.checkPermissions();
    }

    checkPermissions() {
        const p = this.permission;
        const g = this.group;
        const a = this.access;
        // console.log(p, g, a);

        if (p && g && a && p[g] && p[g][a]) {
            this.toggleDisplay(true);
        } else {
            this.toggleDisplay(false);
        }
    }

    toggleDisplay(state: boolean) {
        if (!state) {
            this.el.nativeElement.remove();
        } else {
            // TODO show element her
        }
    }
}
