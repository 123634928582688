export const environment = {
    production: false,
    // Base: 'http://127.0.0.1:8000',
    Base: 'https://devapi.etammn.net',

    pusher: {
        PUSHER_APP_ID: 1557310,
        PUSHER_APP_KEY: '7a07c02432770145445e',
        PUSHER_APP_SECRET: 'c54031046ca346877a6b',
        PUSHER_APP_CLUSTER: 'eu'
        // private pusher server
        // wsHost: '127.0.0.1',
        // wsPort: 6001,
        // enabledTransports: ['ws', 'wss'],
        // PUSHER_APP_ID: 'app-id',
        // PUSHER_APP_KEY: 'app-key',
        // PUSHER_APP_SECRET: 'app-secret',
        // PUSHER_APP_CLUSTER: 'eu'
    },
    ClientCred: {
        phone: '+97433246810',
        password: 'admin123456'
    },
    AdminCred: {
        email: 'admin@eg-tek.test',
        password: 'admin123456'
    },
    MaintenanceCred: {
        email: 'maint-run@mail.com',
        password: 'admin123456'
    },
    FacilityCred: {
        email: 'facilitymanagement1@mail.com',
        password: 'admin123456'
    },
    InsuranceCred: {
        email: 'insurance-admin@mail.com',
        password: 'admin123456'
    }
};
