<a (click)="click()">
    {{ clientUUID }}
</a>

<p-dialog
    [header]="'Heads.Contacts' | translate"
    [draggable]="false"
    [closable]="false"
    [visible]="showContacts"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '50vw', height: '400px' }"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    appendTo="body"
>
    <div class="row">
        <div class="col-md-12 mb-1">
            <p-table [scrollable]="true" [value]="clientContacts" [tableStyle]="{ 'min-width': '50rem' }" [loading]="loading">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 150px">{{ 'Tables.Name' | translate }}</th>
                        <th style="min-width: 150px">{{ 'Tables.Mobile' | translate }}</th>
                        <th style="min-width: 150px">{{ 'Tables.Landline' | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contact>
                    <tr>
                        <td style="min-width: 150px">{{ contact.name }}</td>
                        <td style="min-width: 150px">{{ contact.mobile }}</td>
                        <td style="min-width: 150px">{{ contact.landline }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button (click)="close()" [label]="'Buttons.Cancel' | translate" styleClass="p-button-danger p-button-custom p-button-outlined"></p-button>
    </ng-template>
    <!-- <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No Contacts found.</td>
    </tr>
  </ng-template> -->
</p-dialog>
