import { EventEmitter, Output } from '@angular/core';
/// <reference types="@types/googlemaps" />
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GMap } from 'primeng/gmap';
// import { DomSanitizer } from '@angular/platform-browser';
declare var google: any;
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
    @Input() isOpen?: boolean;
    @Input() lng: number = 0;
    @Input() lat: number = 0;
    @Input() editable: boolean = false;
    @Input() location: any;
    @Output() move: EventEmitter<{ lat: number; lng: number }> = new EventEmitter();

    overlays: any[];
    updatedValue!: { lat: number; lng: number };

    constructor() {
        this.overlays = [];
    }
    display: boolean = false;

    ngOnInit() {
        this.updatedValue = { lat: this.lat, lng: this.lng };
        console.log(this.location);
        let bounds = new google.maps.LatLngBounds();
        this.overlays = [new google.maps.Marker({ position: { lat: this.lat, lng: this.lng }, draggable: this.editable })];
        this.overlays.forEach(marker => {
            bounds.extend(marker.getPosition());
        });
    }

    drag(event: any) {
        const lat = event.overlay.getPosition().lat();
        const lng = event.overlay.getPosition().lng();
        this.updatedValue = { lat, lng };
    }

    update() {
        this.move.emit(this.updatedValue);
    }

    open() {
        this.isOpen = true;
    }

    onClose() {
        this.isOpen = false;
    }
}
