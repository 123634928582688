import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    constructor() {}

    exportCSV(data: any, filename = 'data', headerList: string[]) {
        let csvData = this.convertToCSV(data, headerList);
        let blob = new Blob(['\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;'
        });
        this.downloadFile(filename, blob);
    }

    convertToCSV(data: any, headerList: string[]) {
        let str = '';
        let row = 'S.No,';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < data.length; i++) {
            let line = i + 1 + '';
            for (let index in headerList) {
                let head = headerList[index];

                line += ',' + this.handleValue(data[i][head]);
            }
            str += line + '\r\n';
        }
        return str;
    }

    handleValue(value: any) {
        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (typeof value === 'object') {
            const isArray = Array.isArray(value);
            // console.log(value);
            const newValue = isArray ? value.join('-') : this.handleObjectValue(value);
            return newValue;
        } else if (typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }
        return '';
    }

    handleObjectValue(object: Object) {
        const availableProps = ['name', 'displayName', 'username'];
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key) && availableProps.includes(key)) {
                return object[key];
                break;
            }
        }
    }

    downloadFile(fileName: string, blob: Blob) {
        let dwldLink = document.createElement('a');
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {
            //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', fileName + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}
