import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/@core/services/user/user.service';

@Component({
    selector: 'etammn-status-switch',
    templateUrl: './status-switch.component.html',
    styleUrls: ['./status-switch.component.scss']
})
export class StatusSwitchComponent {
    checked = false;
    constructor(private userService: UserService) {
        this.checked = this.userService.checkAdminReadyStatus();
    }

    changeStatus() {
        console.log('clicked');
        this.checked = !this.checked;
        console.log(this.checked);

        this.userService.changeAdminReadyStatus(this.checked);
    }
}
