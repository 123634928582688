import { LanguageService } from './../language/language.service';
import { SystemType } from './../../../@shared/enums/system-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, switchMap, of, tap } from 'rxjs';
import { ClientLoginCredentials } from './../../../@shared/models/client-login-credentials.model';
import { AdminLoginCredentials } from './../../../@shared/models/admin-login-credentials.model';
import { Injectable } from '@angular/core';
import { objectToQuery } from 'src/app/@shared/helpers/helper';
import { ApiService } from '../api/api.service';
import { Permissions } from 'src/app/@shared/interfaces/permissions/permissions.interface';

@Injectable({
    providedIn: 'root'
})
export abstract class AuthService {
    readonly apiUrl: string = '/v1/';

    constructor(protected apiService: ApiService, private languageService: LanguageService) {}

    login(credentials: AdminLoginCredentials | ClientLoginCredentials, type: SystemType): Observable<any> {
        return this.apiService.post(`${this.apiUrl}${type}/login`, credentials).pipe(
            // switchMap((res) => {
            //   if (res.status === '422') {
            //     return throwError(
            //       () =>
            //         new HttpErrorResponse({
            //           status: 422,
            //         })
            //     );
            //   } else return of(res);
            // }),
            tap(res => {
                localStorage.setItem('token', res.token);
                localStorage.setItem('user', JSON.stringify(res.user));
                localStorage.setItem('permissions', JSON.stringify(res.user.permissions));
                this.languageService.setLang(res.user.lang);
                // this.currentUser = res.user;
            }),
            tap(() => {
                // this.isLoggedIn = true;
                // this._isLoggedIn$.next(true);
            })
        );
    }

    // getUserRole() {
    //   const role = localStorage.getItem('role');

    //   if (role) {
    //     return role;
    //   }
    //   const user = localStorage.getItem('user');
    //   if (user) {
    //     const role = user['clientId'] ? 'client' : 'admin';
    //     return role;
    //   }
    //   return;
    //   //TODO handle all system types here
    // }

    usersControl() {
        let u = localStorage.getItem('user');
        if (!u || u == 'undefined') {
            u = JSON.stringify('');
        }
        return JSON.parse(u).users_control ? Boolean(JSON.parse(u).users_control) : false;
    }

    setUserType(user: any) {
        localStorage.setItem('userType', user.userType);
        localStorage.setItem('is_ready', user.is_ready);
        // localStorage.setItem('roleId', user.role.id);
        // localStorage.setItem('roleName', JSON.stringify(user.role.name));
        // localStorage.setItem('role', JSON.stringify(user.role));
        // localStorage.setItem('role', user['clientId'] ? 'client' : 'admin');
        // const role = localStorage.getItem('role');
        //TODO handle all system types here
    }

    getPermissions(): Permissions | null {
        let p = localStorage.getItem('permissions');
        if (!p || p == 'undefined') {
            p = JSON.stringify('');
        }
        return JSON.parse(p);
    }
}
