import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientsService } from 'src/app/@features/clients/clients.service';
import { SystemsService } from 'src/app/@features/systems/systems.service';

@Component({
    selector: 'etammn-client-id-contacts',
    templateUrl: './client-id-contacts.component.html',
    styleUrls: ['./client-id-contacts.component.scss']
})
export class ClientIdContactsComponent {
    @Input() type!: 1 | 2; // 1 with contacts // 2 without contacts
    @Input() clientId!: number;
    @Input() clientUUID!: string;
    @Input() systemId!: string;
    @Input() set contacts(val: any[]) {
        if (val && this.selected) {
            this.clientContacts = val;
            this.showContacts = true;
            this.loading = false;
        } else {
            this.clientContacts = [];
            this.showContacts = false;
        }
    }
    @Output() select: EventEmitter<boolean> = new EventEmitter<boolean>();
    loading: boolean = true;
    showContacts: boolean = false;
    clientContacts: any[] = [];
    selected: boolean = false;

    constructor(private clientsService: ClientsService) {}

    click() {
        this.loading = true;
        this.selected = true;
        this.showContacts = true;
        if (this.type === 1) {
            this.select.emit(true);
        } else {
            this.getContacts();
        }
    }

    getContacts() {
        this.clientsService.getAll(`${this.clientId}/systems/${this.systemId}`).subscribe({
            next: done => {
                this.clientContacts = done.data.contacts;
                this.loading = false;
            }
        });
    }

    close() {
        this.selected = false;
        this.showContacts = false;
    }
}
