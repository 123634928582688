<div class="phone" [ngClass]="{ 'ng-invalid': control.errors && control.touched }">
    <ngx-intl-tel-input
        [ngClass]="{ 'open-up': isOpeningUp }"
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="true"
        [customPlaceholder]="placeholder"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name, SearchCountryField.DialCode]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="selectedCountry"
        (countryChange)="onSelectedCountryChanged($event)"
        [maxLength]="maxLength"
        [phoneValidation]="phoneValidation"
        [separateDialCode]="true"
        [searchCountryFlag]="true"
        [numberFormat]="PhoneNumberFormat.National"
        [formControl]="control"
        autocomplete="off"
    ></ngx-intl-tel-input>

    <i class="pi pi-phone"></i>
</div>
