<div class="main">
    <ng-container *ngIf="!(isLoggedIn$ | async)">
        <router-outlet></router-outlet>
    </ng-container>
    <ng-container *ngIf="isLoggedIn$ | async">
        <header>
            <div class="left-side">
                <div class="logo left-side" [routerLink]="''">
                    <img src="../../../assets/images/logo.jpg" alt="" />
                </div>
                <!-- <i
                    (click)="toggleSideMenu()"
                    class="pi pi-align-justify"
                    style="font-size: 2rem; margin-left: 150px; margin-right: 150px; cursor: pointer"
                    [ngClass]="{ 'menu-closed': !isOpen }"
                ></i> -->
            </div>
            <div class="right-side">
                <etammn-status-switch *ngIf="systemType === SystemType.Admin"></etammn-status-switch>
                <div class="menu lang">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-default userAvatar" aria-controls="dropdown-basic">
                            <i class="pi pi-language"></i>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="width: 200px">
                            <li role="menuitem" *ngFor="let lang of languageService.languages">
                                <a class="dropdown-item" (click)="languageService.changeLang(lang.key)"><i class="pi pi-globe"></i>{{ lang.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <hr />
                </div>
                <div class="menu">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-default userAvatar" aria-controls="dropdown-basic">
                            <i class="pi pi-user"></i>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="width: 200px">
                            <li role="menuitem">
                                <p style="padding-left: 25px; margin: 0">
                                    {{ currentUser.username }}
                                </p>
                            </li>
                            <li class="divider dropdown-divider"></li>
                            <li role="menuitem">
                                <a class="dropdown-item" routerLink="profile"><i class="pi pi-user-edit mr-2"></i>{{ 'Heads.Profile' | translate }}</a>
                            </li>
                            <li class="divider dropdown-divider"></li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="logout()"><i class="pi pi-user-edit mr-2"></i>{{ 'Heads.Logout' | translate }}</a>
                            </li>
                        </ul>
                    </div>
                    <hr />
                </div>
            </div>
        </header>
        <main>
            <etammn-side-bar [isOpen]="isOpen"></etammn-side-bar>
            <div class="content-section">
                <div class="content">
                    <router-outlet></router-outlet>
                </div>
                <footer>
                    <p>{{ 'Titles.CopyRights' | translate }}</p>
                    <p>{{ 'Titles.AppVersion' | translate }}</p>
                </footer>
            </div>
        </main>
    </ng-container>
</div>
