<p-toast position="top-right"></p-toast>
<!-- .. -->
<p-confirmDialog
    [style]="{ width: '30vw' }"
    [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-danger p-button-custom p-button-outlined"
    acceptButtonStyleClass="p-button-danger p-button-custom"
></p-confirmDialog>

<router-outlet></router-outlet>
