<!-- <ng-container *ngIf="control">
    {{control | json}}
  </ng-container> -->
<!-- {{control.errors | json}} -->
<ng-container *ngIf="control?.errors?.['required'] && control.touched">{{ name }} Is required
</ng-container>
<ng-container *ngIf="(control?.errors?.['pattern'] || control?.errors?.['email'] || control?.errors?.['wrongNumber']) && control.touched">{{ name }} Invalid
</ng-container>
<ng-container *ngIf="control?.errors?.['minlength'] && control.touched">{{ name }} Sould not be less than {{control.errors?.['minlength']?.requiredLength}}
</ng-container>
<ng-container *ngIf="control?.errors?.['notMatch'] && control.touched">{{ name }} Not match {{other}}
</ng-container>