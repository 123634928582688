<div (click)="changeStatus()" style="cursor: pointer">
    <h5 style="margin-top: 10px; position: absolute; z-index: 999; padding-left: 20px; padding-right: 20px">
        {{ checked ? ('Buttons.Ready' | translate) : ('Buttons.NotReady' | translate) }}
    </h5>
    <p-inputSwitch [(ngModel)]="checked"></p-inputSwitch>
</div>
<!-- <p-toggleButton
    [(ngModel)]="checked"
    onLabel="Ready "
    offLabel="Not Ready"
    onIcon="pi pi-check"
    offIcon="pi pi-times"
    [style]="{ width: '9em' }"
    (onChange)="changeStatus()"
></p-toggleButton> -->
