import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeModule } from 'primeng/tree';
import { KnobModule } from 'primeng/knob';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { CalendarModule } from 'primeng/calendar';
import { BadgeModule } from 'primeng/badge';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SplitterModule } from 'primeng/splitter';
import { ChartModule } from 'primeng/chart';
import { GMapModule } from 'primeng/gmap';
import { ImageModule } from 'primeng/image';
import { SelectButtonModule } from 'primeng/selectbutton';

const modules = [
    InputTextModule,
    PasswordModule,
    ButtonModule,
    StepsModule,
    TableModule,
    AccordionModule,
    ProgressBarModule,
    CheckboxModule,
    RippleModule,
    SplitButtonModule,
    MultiSelectModule,
    InputNumberModule,
    DropdownModule,
    DividerModule,
    InputTextModule,
    InputTextareaModule,
    RadioButtonModule,
    InputSwitchModule,
    DialogModule,
    TreeSelectModule,
    TreeModule,
    CardModule,
    KnobModule,
    ConfirmDialogModule,
    ToastModule,
    TabViewModule,
    FileUploadModule,
    VirtualScrollerModule,
    CalendarModule,
    BadgeModule,
    DynamicDialogModule,
    OverlayPanelModule,
    ToggleButtonModule,
    SplitterModule,
    ChartModule,
    GMapModule,
    ImageModule,
    SelectButtonModule
];

@NgModule({
    declarations: [],
    imports: [...modules],
    exports: [...modules]
})
export class PrimeNgModule {}
